require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("select2")
require("@nathanvda/cocoon")
require("jquery.easing")
require("corejs-typeahead/dist/typeahead.jquery")

import toastr from 'toastr';
import '../../../vendor/dashforge/js/dashforge.js'
import '../../../vendor/dashforge/js/dashforge.aside.js'
import '../stylesheets/application.scss'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

toastr.options = {
  toastClass: 'toastr'
};

$(document).ready(function () {
  $(".clickable-row").click(function () {
    window.location = $(this).data("href");
  });

  var typeahead_domains = new Bloodhound({
    prefetch: {
      url: '/domains/autocomplete/',
      cache: false
    },
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name')
  });

  $('.navbar-domain-search').typeahead(
    {
      hint: false,
      highlight: false,
      minLength: 1
    },
    {
      display: 'name',
      source: typeahead_domains,
      limit: 10
    }
  ).bind('typeahead:selected', function (obj, datum) {
    window.location.href = datum.link;
  });

});

(function ($) {
  // jQuery on an empty object, we are going to use this as our Queue
  var ajaxQueue = $({});
  $.ajaxQueue = function (ajaxOpts) {
    var jqXHR,
      dfd = $.Deferred(),
      promise = dfd.promise();
    // queue our ajax request
    ajaxQueue.queue(doRequest);
    // add the abort method
    promise.abort = function (statusText) {
      // proxy abort to the jqXHR if it is active
      if (jqXHR) {
        return jqXHR.abort(statusText);
      }
      // if there wasn't already a jqXHR we need to remove from queue
      var queue = ajaxQueue.queue(),
        index = $.inArray(doRequest, queue);
      if (index > -1) {
        queue.splice(index, 1);
      }
      // and then reject the deferred
      dfd.rejectWith(ajaxOpts.context || ajaxOpts, [promise, statusText, ""]);
      return promise;
    };
    // run the actual query
    function doRequest(next) {
      jqXHR = $.ajax(ajaxOpts)
        .done(dfd.resolve)
        .fail(dfd.reject)
        .then(next, next);
    }
    return promise;
  };
})(jQuery);

function clickable_ajax_row(elem) {
  $.ajax({
    dataType: 'script',
    url: $(elem).data("href")
  });
}

window.clickable_ajax_row = clickable_ajax_row;
window.toastr = toastr;